import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import HelpDialogCross from "../../assets/Images/help_dialog_cross.svg";
import { useEffect, useState } from "react";
import { assistiveTechnologyItems } from "./HelpAssistiveDialogContent/HelpAssistiveTechnologyDialogContent";

export interface AssistiveTechnolgyProps {
  name: string;
  description: any;
  showStatus: boolean;
  setExpandCollapse: (ind: number) => void;
  id: number;
}

const AssistiveTechnolgy = () => {
  const [itemsOpen, setItemsOpen] = useState<boolean[]>([]);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handletItemsOpenStatus = (index: number) => {
    let tempData = [...itemsOpen];
    tempData[index] = !tempData[index];
    setItemsOpen(tempData);
  };

  const handleExpandAll = () => {
    let tempData = itemsOpen.map(() => true);
    setItemsOpen(tempData);
  };

  const handleCollapseAll = () => {
    let tempData = itemsOpen.map(() => false);
    setItemsOpen(tempData);
  };

  useEffect(() => {
    let arr = assistiveTechnologyItems.map(() => false);
    setItemsOpen(arr);
  }, [assistiveTechnologyItems]);

  return (
    <>
      <div className="flex items-center justify-between gap-[10px] w-full">
        <p className="text-lightBlack font-inter text-4xl font-normal">
          Assistive Technolgy
        </p>
        <button className="mt-auto" onClick={handleClose}>
          <img src={HelpDialogCross} />
        </button>
      </div>

      <div className="flex w-full justify-end items-center border-b border-b-darkGrey">
        <div className="flex items-center gap-4">
          <button
            className="text-primaryColor font-inter text-base font-semibold hover:underline"
            onClick={handleExpandAll}
          >
            Expand All
          </button>
          <div className="w-[1px] h-[30px] bg-darkGrey"></div>
          <button
            className="text-primaryColor font-inter text-base font-semibold hover:underline"
            onClick={handleCollapseAll}
          >
            Collapse All
          </button>
        </div>
      </div>
      <section className="overflow-y-auto overflow-x-hidden h-[723px] scroll4">
        {assistiveTechnologyItems.map((obj) => (
          <div
            className="border-b border-b-darkGrey hover:bg-gray-100"
            key={obj.id}
          >
            <AssistiveTech
              name={obj.name}
              id={obj.id}
              description={obj.description}
              showStatus={itemsOpen[obj.id]}
              setExpandCollapse={handletItemsOpenStatus}
            />
          </div>
        ))}
      </section>

      <div className="flex w-full h-[100px] justify-center items-center flex-shrink-0">
        <button
          onClick={handleClose}
          className="flex py-3 px-5 justify-center items-center gap-[10px] bg-vibrantYellow rounded-[49px] border border-lightBlack font-semibold btn-hover-style"
        >
          Close
        </button>
      </div>
    </>
  );
};

export default AssistiveTechnolgy;

export const AssistiveTech = ({
  name,
  description,
  showStatus,
  setExpandCollapse,
  id,
}: AssistiveTechnolgyProps) => {
  return (
    <div>
      <div
        className={`flex justify-between items-center px-5 text-lightBlack `}
        onClick={() => setExpandCollapse(id)}
      >
        <h3 className="text-xl cursor-pointer hover:underline py-5 ">{name}</h3>
        <p
          className={`${
            showStatus ? "hidden" : "block"
          } text-xl cursor-pointer`}
        >
          &#43;
        </p>
        <p
          className={`${
            showStatus ? "block" : "hidden"
          } text-xl cursor-pointer`}
        >
          &#8722;
        </p>
      </div>
      <div>
        {showStatus && (
          <p className=" p-5 w-[832px] py-[18px] items-center bg-custom-light-grey-gradient text-lightBlack text-[16px] leading-normal">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
