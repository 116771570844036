import { useEffect, useState } from "react";
import QuestionsLeftPane, { Category, FilterValue } from "./QuestionsLeftPane";
import RawScoreCard from "./RawScoreCard";
import FinalScoreCard from "./FinalScoreCard";
import QuestionContent from "./QuestionContent";
import Explanation from "./Explanation";
import { useGetUserResultsByIdQuery } from "../../redux/API/StartTest/startTestApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { useNavigate, useParams } from "react-router-dom";
import { getItem, setItem } from "../../utils/token";
import {
  setIsTestStarted,
  setResetFilters,
  setQid,
  setIsTestInstructionsOpen,
  setFilters,
} from "../../redux/Slices/startFullTestSlice";
import LinkExpired from "../ReUsableComponents/LinkExpired";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { setCurrentQuestionIndex } from "../../redux/Slices/resultSlice";
import { setCurrentQuestion } from "../../redux/Slices/resultSlice";
import { jwtDecode } from "jwt-decode";
import Header from "../../pages/NewDashboard/Header";
import FiltersPopUp from "./FiltersPopUp";

// Types

export type ScoreModule = {
  name: string;
  score: string;
  total?: string;
  subModules?: {
    name: string;
    score: string;
  }[];
};
export type ScoreSection = {
  title: string;
  totalScore: string;
  modules: ScoreModule[];
};

const TestResults = () => {
  const [openPanels, setOpenPanels] = useState({
    rawScore: true,
    finalScore: true,
  });
  const { isFullTestResultScreen, filteredData, filters } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const tokenParam = params.get("token");
  const istokenParam = tokenParam ? true : false;
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (tokenParam) {
      setItem("route", `/practices/results/${id}`);
      setItem("token", atob(tokenParam));
      setItem("isLogin", "true");
      const decoded: any = jwtDecode(atob(tokenParam));
      setItem("userName", decoded?.firstName);
      setItem("userRole", decoded?.role ?? null);
      setItem("userEmail", decoded?.userEmail);
      const expiryTime = decoded.exp;
      if (expiryTime) {
        setItem("expiryTime", expiryTime.toString());
      }
    }
  }, [id, tokenParam]);

  const isParent = getItem("userRole") === "PARENT";
  const userId = isParent ? getItem("studentId") ?? "" : "";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsTestStarted(false));
    dispatch(setIsTestInstructionsOpen(false));
  }, [dispatch]);

  const { error,isError } = useGetUserResultsByIdQuery(
    { userId, testId: id || "" },
    { refetchOnMountOrArgChange: true, skip: !id }
  );

  const navigate = useNavigate();
  const role = getItem("userRole");

  const togglePanel = (panel: keyof typeof openPanels) => {
    setOpenPanels({ ...openPanels, [panel]: !openPanels[panel] });
  };

  useEffect(() => {
    if (error && "status" in error) {
      if (error.status === 400) {
        if (role === "STUDENT") {
          navigate("/dashboard");
        } else {
          navigate("/parentdashboard");
        }
      } else if (error.status === 401){
        navigate('/login')
      }
    }
  }, [error, role]);

  const handleClickExit = () => {
    dispatch(setQid(""));
    dispatch(setCurrentQuestion(""));
    dispatch(setCurrentQuestionIndex(""));
    dispatch(setResetFilters());

    if (isParent) {
      navigate("/parentdashboard");
    } else {
      navigate("/practices/results");
    }
  };

    const toggleFilter = (category: Category, value: FilterValue) => {
      const newValue =
        !filters[category][value as keyof (typeof filters)[Category]];
      const categoryValues = Object.values(filters[category]);
      if (!newValue && categoryValues.filter(Boolean).length === 1) return;
      dispatch(
        setFilters({
          ...filters,
          [category]: {
            ...filters[category],
            [value]:
              !filters[category][value as keyof (typeof filters)[Category]],
          },
        })
      );
    };

  return (
    <>
      <Header route={istokenParam ? `/practices/results/${id}` : ""} />
      <div className="w-full mt-8 mb-4 mx-auto bg-white rounded-lg px-4 font-gully">
        {isError ? (
          <LinkExpired redirectTo="/dashboard" />
        ) : (
          <div className="grid grid-cols-[auto,1fr] gap-2 h-screen mb-16">
            <div className="relative">
            {!isFullTestResultScreen && (
              <QuestionsLeftPane
                setShowFilters={setShowFilters}
                showFilters={showFilters}
              />
            )}
            {/* Filters Dropdown */}
            {showFilters && (
              <FiltersPopUp
                toggleFilter={toggleFilter}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
              />
            )}
            </div>
            <div className={"flex flex-col flex-grow"}>
              {!isFullTestResultScreen && (
                <div className="flex gap-2 mb-2">
                  {/* Raw Score Card */}
                  <RawScoreCard
                    togglePanel={(panel) =>
                      togglePanel(panel as "rawScore" | "finalScore")
                    }
                    openPanels={openPanels}
                  />

                  {/* Final Score Card */}
                  <FinalScoreCard
                    togglePanel={(panel) =>
                      togglePanel(panel as "rawScore" | "finalScore")
                    }
                    openPanels={openPanels}
                  />

                  <div className="basis-[10%]">
                    <AuthButton
                      text="Exit"
                      onClick={handleClickExit}
                      className="h-[60px] font-bold text-white px-6 text-[21px]"
                    />
                  </div>
                </div>
              )}
              <div
                style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
                className="rounded-xl px-6 pt-6 pb-2 mb-2 w-full"
              >
                <QuestionContent />
              </div>
              {!isFullTestResultScreen && filteredData?.length > 0 && (
                <div
                  style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
                  className="p-6 rounded-xl"
                >
                  <Explanation />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestResults;
