import { useNavigate } from "react-router-dom";
import testCompletion from "../../assets/Images/test-completed.png";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";

import { getItem } from "../../utils/token";
import macBook from "../../assets/Images/Macbook.svg";
import { setIsTestInstructionsOpen, setIsTestStarted } from "../../redux/Slices/startFullTestSlice";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { FaRegStickyNote } from "react-icons/fa";
import Header from "../../pages/NewDashboard/Header";
import { RxHome } from "react-icons/rx";

const TestCompleted = () => {
  const navigate = useNavigate();
  const { startTestSlice } = useSelector(selectFullTestState);
  const dispatch = useDispatch();
  const isPreview = getItem("testType") === "PREVIEW";
  const { testId } = startTestSlice;

  const handleReturntoHome = () => {
    dispatch(setIsTestInstructionsOpen(false));
    dispatch(setIsTestStarted(false));
  };

  return (
    <>
      <Header />
      <div className="flex h-screen items-center flex-col z-50">
        <div
          className="h-fit flex justify-center items-center flex-col p-6 mt-16"
          style={{ boxShadow: "0px 0px 10px 3px #2020201A" }}
        >
          <p className="text-center text-[40px] mb-11 -z-10 text-appPrimaryColor font-bold font-gully">
            You’re All Finished!
          </p>

          {isPreview ? (
            <div
              className="flex flex-col items-center justify-center leading-6 w-[448px] rounded-md text-sm"
            >
              <img src={testCompletion} alt="macbook" />
              <p className="text-center">
                Return to the homepage to start the preview again.
              </p>
              <p className="text-center mb-4">
                Reminder: there are no scores of feedback in this preview.
              </p>
              <p className="mb-6 min-w-11 text-center">
                When you see this page on test day, you'll know your scores have
                been submitted.
              </p>
              <AuthButton
                text="Return To Home"
                icon={<RxHome />}
                onClick={handleReturntoHome}
              />
            </div>
          ) : (
            <>
              <img
                className="w-[350px] h-[350px] mb-5 -z-10"
                src={testCompletion}
                alt="test completion"
              />
              <p className="text-[21px] font-medium text-center pb-8">
                Congratulations on completing an SAT practice test.
              </p>
              <div className="w-full flex items-center justify-between">
                <AuthButton
                  text="Return To Home"
                  icon={<RxHome />}
                  onClick={handleReturntoHome}
                />
                <AuthButton
                  text="View Score"
                  onClick={() => navigate(`/practices/results/${testId}`)}
                  icon={<FaRegStickyNote />}
                />
              </div>
            </>
          )}
        </div>
        <p className="mt-9 text-[21px] font-medium">
          Need Help? Reach out to our support team at{" "}
        </p>
        <p className="text-appPrimaryColor underline text-[21px] font-medium mt-1">
          support@fullmarks.io
        </p>
        .
      </div>
    </>
  );
};

export default TestCompleted;
