import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import {
  HightLightTextData,
  setHighlightedContent,
  setHighlightPopup,
  setSelectionBgColor,
  handleDeleteSelectedSentence,
  setOpenNotes,
  setQuestionId,
} from "../../redux/Slices/startFullTestSlice";
import YellowCircle from "../../assets/Images/YellowCircle.svg";
import BlueCircle from "../../assets/Images/BlueCircle.svg";
import PinkCircle from "../../assets/Images/PinkCircle.svg";
import DeleteIcon from "../../assets/Images/PopupDelete.svg";
import AddIcon from "../../assets/Images/PopupAddNotes.svg";
import PinkDrop from "../../assets/Images/PinkDrop.svg";
import YellowDrop from "../../assets/Images/YellowDrop.svg";
import BlueDrop from "../../assets/Images/BlueDrop.svg";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import useSendTestActivity, { ActivityData } from "../customHook/useUserTestActivity";
import { getItem } from "../../utils/token";

interface PopupProps {
  questionId: string;
  startIndex: number | null;
  selectedText: string;
  startTime: Date | null;
  module: 301 | 302;
  section: 701 | 702;
}

interface UnderlineProps {
  handleChangeUnderline: (underlineStyle: string) => void;
}

interface NoteSectionProps {
  NoteOpen: boolean;
  highlightedContent: HightLightTextData[];
  questionId: string;
  selectedSentence: string;
  handleSetNotes: (data: HightLightTextData) => void;
}

interface DeleteNoteScreen {
  selectedText: string | null;
  startIndex: number | null;
}

const HighlightTextPopup = ({
  questionId,
  startIndex,
  selectedText,
  startTime,
  module,
  section
}: PopupProps) => {
  const { highlightedContent, highlightPopupPosition, userTestId, count } =
    useSelector((state: RootState) => ({
      highlightedContent: state.root.startFullTest.highlightedContent,
      highlightPopupPosition: state.root.startFullTest.highlightPopupPosition,
      userTestId: state.root.startFullTest.userTestId,
      count: state.root.startFullTest.count,
    }));

  const [underlineDropdown, setUnderlineDropdown] = useState<boolean>(false);
  const sendTestActivity = useSendTestActivity();
  const isPreview = getItem('testType') === 'PREVIEW'

  const dispatch = useDispatch();

  const popupRef = useRef<HTMLDivElement>(null);

  let updatedHighlightedContent = [...highlightedContent];

  const getSelectedTextData = (
    qId: string,
    text: string,
    startIndex: number | null
  ) => {
    let existingQuestionIndex = updatedHighlightedContent.findIndex(
      (item) => item.questionId === qId
    );

    if (existingQuestionIndex !== -1 && startIndex !== null) {
      let questionHighlights = [
        ...updatedHighlightedContent[existingQuestionIndex].hightLightText,
      ];
      let selected = questionHighlights.find(
        (item) => item.selectedText === text && item.startIndex === startIndex
      );

      if (selected) {
        return { selected, existingQuestionIndex, questionHighlights };
      }
    }

    return null;
  };

  const getBgColor = () => {
    let selectedTextData = getSelectedTextData(
      questionId,
      selectedText,
      startIndex
    );
    if (selectedTextData) {
      const { selected } = selectedTextData;
      return selected.bgColor;
    }
    return "";
  };

  const handleChangeColor = (color: string) => {
    let selectedTextData = getSelectedTextData(
      questionId,
      selectedText,
      startIndex
    );
    if (selectedTextData) {
      let { selected, existingQuestionIndex, questionHighlights } =
        selectedTextData;

      const updatedSelected = { ...selected, bgColor: color };

      updatedHighlightedContent[existingQuestionIndex] = {
        ...updatedHighlightedContent[existingQuestionIndex],
        hightLightText: questionHighlights.map((highlight) =>
          highlight.selectedText === selectedText &&
          highlight.startIndex === selected.startIndex
            ? updatedSelected
            : highlight
        ),
      };

      const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
      dispatch(setHighlightedContent(updatedData));
      dispatch(setSelectionBgColor(color));
    }
  };

  const handleChangeUnderline = (underline: string) => {
    let selectedTextData = getSelectedTextData(
      questionId,
      selectedText,
      startIndex
    );
    if (selectedTextData) {
      let { selected, existingQuestionIndex, questionHighlights } =
        selectedTextData;

      const updatedSelected = { ...selected, underline: underline };

      updatedHighlightedContent[existingQuestionIndex] = {
        ...updatedHighlightedContent[existingQuestionIndex],
        hightLightText: questionHighlights.map((highlight) =>
          highlight.selectedText === selectedText &&
          highlight.startIndex === selected.startIndex
            ? updatedSelected
            : highlight
        ),
      };

      const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
      dispatch(setHighlightedContent(updatedData));
    }
  };

  const handleAddNote = () => {
    let selectedTextData = getSelectedTextData(
      questionId,
      selectedText,
      startIndex
    );
    if (!startTime) return;
    if(!isPreview){
    const endTime = new Date();
      const activityData: ActivityData = {
        testId: userTestId,
        startTime,
        endTime,
        module,
        section,
        fromQuestion: count + 1,
        toQuestion: count + 1,
        event: 505,
        questionId,
      };
      dispatch(setQuestionId(questionId));
      sendTestActivity(activityData);
    }
    if (selectedTextData) {
      let { selected, existingQuestionIndex, questionHighlights } =
        selectedTextData;

      const updatedSelected = { ...selected, isNotes: true };

      updatedHighlightedContent[existingQuestionIndex] = {
        ...updatedHighlightedContent[existingQuestionIndex],
        hightLightText: questionHighlights.map((highlight) =>
          highlight.selectedText === selectedText &&
          highlight.startIndex === selected.startIndex
            ? updatedSelected
            : highlight
        ),
      };

      const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
      dispatch(setHighlightedContent(updatedData));
      dispatch(setHighlightPopup(false));
      dispatch(setOpenNotes(true));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        dispatch(setHighlightPopup(false));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div
      ref={popupRef}
      className="absolute z-50 bg-white flex rounded-[50px] shadow-custom-black w-72 h-12 justify-center items-center gap-[10px] shrink-0"
      style={{
        top: `${Number(highlightPopupPosition.top) - 50}px`,
        left: `${Number(highlightPopupPosition.left) - 40}px`,
      }}
    >
      <button
        className="relative hover:border hover:border-lightBlack hover:rounded-full"
        onClick={() => handleChangeColor("lightYellow")}
      >
        <img src={YellowCircle} alt="yellow-circle" />
        {getBgColor() === "lightYellow" && (
          <img
            src={YellowDrop}
            alt="yellow-drop"
            className="absolute inset-0 m-auto"
          />
        )}
      </button>
      <button
        className="relative hover:border hover:border-lightBlack hover:rounded-full"
        onClick={() => handleChangeColor("lightBlue")}
      >
        <img src={BlueCircle} alt="blue-circle" />
        {getBgColor() === "lightBlue" && (
          <img
            src={BlueDrop}
            alt="blue-drop"
            className="absolute inset-0 m-auto"
          />
        )}
      </button>
      <button
        className="relative hover:border hover:border-lightBlack hover:rounded-full"
        onClick={() => handleChangeColor("lightPink")}
      >
        <img src={PinkCircle} alt="pink-circle" />
        {getBgColor() === "lightPink" && (
          <img
            src={PinkDrop}
            alt="pink-drop"
            className="absolute inset-0 m-auto"
          />
        )}
      </button>
      <button
        className="flex w-9 h-9 justify-center items-center gap-[2px] shrink-0 relative focus:outline-none focus:border-b-2 focus:border-black"
        onClick={() => setUnderlineDropdown(!underlineDropdown)}
      >
        <div className="flex flex-col justify-center items-center gap-[3px]">
          <p className="font-inter text-xs font-medium">U</p>
          <div className="h-[1px] w-[18px] bg-black"></div>
          <DashedLine />
          <DottedLine />
        </div>
        {underlineDropdown ? (
          <IoIosArrowUp className="w-4 h-2 text-black" />
        ) : (
          <IoIosArrowDown className="w-4 h-2 text-black" />
        )}
        {underlineDropdown && (
          <Underlinedropdown handleChangeUnderline={handleChangeUnderline} />
        )}
      </button>
      <div className="relative group">
        <button
          className="flex w-9 h-9 p-[6px] justify-center items-center rounded-[17.5px] border border-lightBlack hover:border-2"
          onClick={() =>
            dispatch(
              handleDeleteSelectedSentence({
                questionId,
                selectedText,
                startIndex,
              })
            )
          }
        >
          <img src={DeleteIcon} alt="delete-icon" />
          <p className="absolute inter bottom-[110%] mb-1 w-32 left-1/2 transform -translate-x-1/2 invisible group-hover:visible bg-slate-600 text-white text-sm rounded px-2 py-1">
            Delete highlight
          </p>

        </button>
      </div>
      <div className="w-[.5px] h-6 bg-darkGrey"></div>
      <button
        className="flex w-9 h-9 p-[6px] justify-center items-center rounded-[17.5px] border border-lightBlack hover:border-2"
        onClick={handleAddNote}
      >
        <img src={AddIcon} alt="Add-icon" />
      </button>
    </div>
  );
};

export default HighlightTextPopup;

export const DashedLine = () => {
  return (
    <div className="grid grid-cols-5 gap-0.5 w-[18px]">
      <div className="bg-black h-[1px] col-span-1"></div>
      <div className="bg-black h-[1px] col-span-3"></div>
      <div className="bg-black h-[1px] col-span-1"></div>
    </div>
  );
};

export const DottedLine = () => {
  return (
    <div className="grid grid-cols-7 gap-[1px] w-[18px]">
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
    </div>
  );
};

export const Underlinedropdown = ({
  handleChangeUnderline,
}: UnderlineProps) => {
  return (
    <div className="absolute top-10 -left-1 rounded-b-md shadow-custom-black flex w-11 flex-col justify-center items-center bg-white">
      <button
        className="flex py-1 w-full items-center flex-col justify-center hover:bg-lightGrey hover:border-l-2 hover:border-lightBlack"
        onClick={() => handleChangeUnderline("solid")}
      >
        <p className="font-inter text-xs font-medium">U</p>
        <div className="bg-black w-[18px] h-[1px]"></div>
      </button>
      <button
        className="flex py-1 w-full items-center flex-col justify-center hover:bg-lightGrey hover:border-l-2 hover:border-lightBlack"
        onClick={() => handleChangeUnderline("dashed")}
      >
        <p className="font-inter text-xs font-medium">U</p>
        <DashedLine />
      </button>
      <button
        className="flex py-1 w-full items-center flex-col justify-center hover:bg-lightGrey hover:border-l-2 hover:border-lightBlack"
        onClick={() => handleChangeUnderline("dotted")}
      >
        <p className="font-inter text-xs font-medium">U</p>
        <DottedLine />
      </button>
      <button
        className="flex py-1 w-full items-center flex-col justify-center text-xs font-light font-inter hover:bg-lightGrey hover:border-l-2 hover:border-lightBlack"
        onClick={() => handleChangeUnderline("none")}
      >
        None
      </button>
    </div>
  );
};

export const NoteSection = ({
  NoteOpen,
  highlightedContent,
  questionId,
  selectedSentence,
  handleSetNotes,
}: NoteSectionProps) => {
  const updatedHighlightedContent = [...highlightedContent];

  const [isYesActive, setIsYesActive] = useState(false);
  const [deleteScreen, setDeleteScreen] = useState<DeleteNoteScreen>({
    selectedText: null,
    startIndex: null,
  });

  const getNoteItems = () => {
    const existingQuestion = updatedHighlightedContent.find(
      (item) => item.questionId === questionId
    );
    return (
      existingQuestion?.hightLightText.filter((item) => item.isNotes) ?? []
    );
  };

  const updateHighlightedContent = (
    questionIndex: number,
    updatedTextItems: any
  ) => {
    const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
    updatedData[questionIndex].hightLightText = updatedTextItems;
    handleSetNotes(updatedData);
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    text: string,
    startIndex: number
  ) => {
    const { value } = e.target;
    const questionIndex = updatedHighlightedContent.findIndex(
      (item) => item.questionId === questionId
    );

    if (questionIndex !== -1) {
      const questionHighlights =
        updatedHighlightedContent[questionIndex].hightLightText;
      const updatedTextItems = questionHighlights.map((highlight) =>
        highlight.selectedText === text && highlight.startIndex === startIndex
          ? { ...highlight, notes: value }
          : highlight
      );
      updateHighlightedContent(questionIndex, updatedTextItems);
    }
  };

  const handleDeleteNote = (text: string, startIndex: number) => {
    setIsYesActive(true);
    const questionIndex = updatedHighlightedContent.findIndex(
      (item) => item.questionId === questionId
    );

    if (questionIndex !== -1) {
      const questionHighlights =
        updatedHighlightedContent[questionIndex].hightLightText;
      const updatedTextItems = questionHighlights.map((highlight) =>
        highlight.selectedText === text && highlight.startIndex === startIndex
          ? { ...highlight, notes: "", isNotes: false }
          : highlight
      );
      updateHighlightedContent(questionIndex, updatedTextItems);
    }
    setIsYesActive(false);
  };

  const handleDeleteScreen = (selectedText: string, startIndex: number) => {
    setDeleteScreen({ selectedText: selectedText, startIndex: startIndex });
  };

  const handleNoButton = () => {
    setDeleteScreen({ selectedText: null, startIndex: null });
    setIsYesActive(false);
  };

  const noteCards = getNoteItems();
  const customColors: Record<string, string> = {
    lightBlue: "bg-lightBlue",
    lightYellow: "bg-lightYellow",
    lightPink: "bg-lightPink",
  };

  return (
    <>
      {NoteOpen && (
        <div
          className="flex flex-col space-y-5 py-5 overflow-y-auto"
          style={{ height: `calc(100vh - 184px)` }}
        >
          {noteCards.map((item) => (
            <div
              key={item.selectedText}
              className="flex flex-col w-52 h-[72px] mx-auto items-start rounded-xl border border-lightBlack relative"
            >
              <div
                className={`flex h-9 px-[10px] py-2 items-center justify-between gap-[22px] self-stretch rounded-t-xl ${
                  customColors[item.bgColor]
                }`}
              >
                <p className="font-inter text-sm font-semibold">
                  {item.selectedText.length > 18
                    ? `${item.selectedText.substring(0, 18)}...`
                    : item.selectedText}
                </p>
                <button
                  className="flex w-7 h-7 p-[3px] justify-center items-center rounded-[17.5px] border bg-white border-lightBlack"
                  onClick={() =>
                    handleDeleteScreen(item.selectedText, item.startIndex)
                  }
                >
                  <img src={DeleteIcon} alt="delete-icon" />
                </button>
              </div>
              <input
                type="text"
                placeholder="Notes are saved automatically"
                value={item.notes}
                className="flex px-[10px] py-[8.5px] items-center gap-[10px] self-stretch rounded-b-xl text-[#919191] font-inter text-xs font-medium"
                onChange={(e) =>
                  handleTextChange(e, item.selectedText, item.startIndex)
                }
              />
              {deleteScreen.selectedText === item.selectedText &&
                deleteScreen.startIndex === item.startIndex && (
                  <div
                    key={item.selectedText}
                    className="flex flex-col bg-black bg-opacity-85 w-52 h-[72px] mx-auto items-start justify-center gap-2 rounded-xl border border-lightBlack px-3 py-2 absolute top-0 left-0"
                  >
                    <p className="font-inter text-sm font-bold text-white">
                      Delete This Note?
                    </p>
                    <div className="flex justify-center items-center gap-8 self-stretch">
                      <button
                        onClick={handleNoButton}
                        className={`flex py-1 px-3 justify-center items-center rounded-[49px] border-[3px] font-inter text-xs font-extrabold text-white ${
                          !isYesActive
                            ? "border-white  underline"
                            : "border-transparent text-gray-400"
                        }`}
                      >
                        No
                      </button>
                      <button
                        onClick={() =>
                          handleDeleteNote(item.selectedText, item.startIndex)
                        } // Set "Yes" as active
                        className={`flex py-1 px-2 justify-center items-center rounded-[49px] border-[3px] font-inter text-xs font-extrabold text-white ${
                          isYesActive
                            ? "border-white underline"
                            : "border-transparent text-gray-400"
                        }`}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
