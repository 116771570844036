import React, { useEffect, useState } from "react";
import { setCreateAccount, setEmailError } from "../../redux/Slices/loginSlice";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import { getItem, setItem } from "../../utils/token";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useSignInMutation } from "../../redux/API/Login/loginApi";
import { RootState } from "../../redux/Store/store";
import { useNavigate } from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";
import workingWomen from "../../assets/Images/working-women.svg";
import { FcGoogle } from "react-icons/fc";

import { IoWarningOutline } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { GrCircleAlert } from "react-icons/gr";
import AuthButton from "./LoginButton";
import { appleClientId, appleUrl, googleUrl } from "../../apiConfig";
import AppleLogin from "react-apple-login";
import { BsApple } from "react-icons/bs";

function LoginV1() {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [userSignInData, setUserSignInData] = useState({
    userName: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [signIn, { isLoading: signInLoading }] = useSignInMutation();
  const { emailError } = useSelector(
    (state: RootState) => state.root.loginSlice
  );
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const handleChangeSignIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setEmailError(""));
    setError("");
    setUserSignInData((prevState) => ({
      ...prevState,
      [name]: value?.trim(),
    }));
  };

  const validateEmail = (email: string) => {
    if (!emailPattern.test(email)) {
      dispatch(setEmailError("Please sign in with a valid email"));
      return false;
    }
    return true;
  };

  const handleSuccess = (response: any) => {
    if (response.isFirstTimeLogin) {
      navigate("/profilesetup");
    }
    const { token, isLogin, refreshToken,route } = response;
    setItem("isLogin", isLogin);
    if (token) {
      setItem("token", token);
      setItem("refreshToken", refreshToken);
      const decoded: any = jwtDecode(token);
      setItem("userName", decoded?.firstName);
      setItem("userRole", decoded?.role ?? null);
      setItem("userEmail", decoded?.userEmail);
      const expiryTime = decoded.exp;
      if (decoded.role && !route) {
        decoded.role === "PARENT"
          ? navigate("/parentdashboard")
          : navigate("/dashboard");
      } else if (decoded.role === "PARENT" && route) {
        navigate(route);
      } else if (decoded.role === "STUDENT" && route) {
        navigate(route);
      }
      if (expiryTime) {
        setItem("expiryTime", expiryTime.toString());
      }
    }
  };

  const handleError = (error: any) => {
    if (error.status === 401) {
      setError("Your email or password is wrong. Please try again");
    } else {
      setError(error?.data?.message);
    }
  };

  const handleGoogleSignIn = () => {
    window.open(googleUrl,'_self');
  }

  const handleLogIn = async () => {
    if (!validateEmail(userSignInData.userName)) {
      return;
    }
    try {
      const route = getItem('route')
      const payload = {
        ...userSignInData,
        userName: userSignInData?.userName?.trim()?.toLowerCase(),
        route
      };
      const res: any = await signIn(payload).unwrap();
      if (res.isLogin) {
        handleSuccess(res);
      } else {
        setError(res?.message);
      }
    } catch (error: any) {
      handleError(error);
    }
  };
  useEffect(() => {
    setItem("token", "");
  }, []);

  useEffect(() => {
    if (userSignInData?.userName && userSignInData?.password) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          handleLogIn();
        }
      };
      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [userSignInData]);

  return (
    <div className="bg-white flex justify-center items-center h-screen w-full mx-auto font-gully">
      <div className="w-1/2 h-screen hidden lg:block">
        <img
          src={workingWomen}
          alt="Placeholder"
          className="object-cover w-full h-full"
        />
      </div>
      <div className="lg:p-[110px] xl:p-[130px] 2xl:p-[150px] 3xl:p-[250px] w-1/2 flex items-center justify-center flex-col">
        <div className="w-full">
          <div className="flex justify-center items-center gap-x-2 mb-9">
            <p className="font-bold text-[#283044] text-center text-4xl font-pretendard cursor-pointer">
              FullMarks! <span className="text-appPrimaryColor">SATprep</span>
            </p>
          </div>{" "}
          <div className="flex justify-center items-center flex-col w-full">
            <div className="w-[450px]">
              {emailError && (
                <div className="flex items-center gap-x-1  pb-3">
                  <IoWarningOutline size={14} className="text-[#D00000]" />
                  <p className="text-[#D00000] text-xs font-semibold">
                    {emailError}
                  </p>
                </div>
              )}
              {error && (
                <div className="flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
                  <div>
                    <GrCircleAlert
                      size={24}
                      className="text-[#D00000] mr-[10px]"
                    />
                  </div>
                  <p className="text-[#D00000] text-base font-semibold">
                    {error}
                  </p>
                </div>
              )}
            </div>
            <div>
              <CustomTextField
                onChangeHandler={handleChangeSignIn}
                placeholder="Email "
                type="text"
                name="userName"
                mandatory={true}
                value={userSignInData?.userName?.trim()}
                className="md:!w-[446px] w-full !border-[#646464]"
                isError={emailError !== ""}
                containerClass="mb-3"
              />
            </div>
            <div className="flex justify-between relative">
              <CustomTextField
                onChangeHandler={handleChangeSignIn}
                placeholder="Password"
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                mandatory={true}
                value={userSignInData?.password?.trim()}
                className="md:!w-[446px] w-full !border-[#646464]"
              />
              {userSignInData.password && (
                <button
                  className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdOutlineRemoveRedEye />
                  ) : (
                    <RiEyeCloseLine />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="my-6 flex justify-center">
            <AuthButton
              text="Sign In"
              icon={<LuUser />}
              iconPosition="left"
              onClick={handleLogIn}
              loader={signInLoading}
              disabled={
                signInLoading ||
                !userSignInData.userName ||
                !userSignInData.password
              }
            />
          </div>
          <div className="flex flex-col gap-6">
             <div className="flex justify-center items-center gap-[6px]">
               <div className="w-20 h-[2px] bg-[#646464]"></div>
               <p className="text-center font-gully text-[16px] text-[#646464]">or</p>
               <div className="w-20 h-[2px] bg-[#646464]"></div>
             </div>

             <div className="flex gap-3 justify-center items-center">
               <AppleLogin  
                clientId={appleClientId} 
                scope={'name email'}
                redirectURI={appleUrl}   
                responseType={"code id_token"} 
                responseMode={"form_post"}  
                render={(renderProps) => (
                  <button 
                    onClick={renderProps.onClick} 
                    className="flex px-[34px] py-[14px] justify-center items-center rounded-xl border border-appPrimaryColor"
                  >
                    <BsApple size={24}  />
                  </button>
                )}
                usePopup={false}
              />
               <button 
                className="flex px-[34px] py-[14px] justify-center items-center rounded-xl border border-appPrimaryColor"
                onClick={handleGoogleSignIn}
               >
                  <FcGoogle size={24}/>
               </button>
             </div>

          </div>
          <div className="flex justify-center items-center mt-9 gap-x-1 whitespace-nowrap">
            <p className="text-[#202020]">Need an account? </p>
            <button
              className="underline text-[#327AEC] flex justify-center text-base mr-6 cursor-pointer"
              onClick={() => {
                navigate("/socialsignup");
                dispatch(setCreateAccount(true));
                setError("");
                dispatch(setEmailError(""));
                setUserSignInData({
                  userName: "",
                  password: "",
                });
              }}
            >
              Sign up!
            </button>
            <p>
              Help with password?{" "}
              <button
                onClick={() => navigate("/forgotPassword")}
                className="underline text-[#327AEC] cursor-pointer"
              >
                Help is here!
              </button>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginV1;
