import { ChartOptions,Chart as ChartJS } from "chart.js";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { DomainWidgetProps, BarChartData } from "./dataTypes";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ChartDataLabels);

const DomainWidget = ({ domainResults }: DomainWidgetProps) => {
  const [selectedTest, setSelectedTest] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [sectionDropdownOpen, setSectionDropdownOpen] = useState(false);
  const [testDropdownOpen, setTestDropdownOpen] = useState(false);

  const generateGraphData = () => {
    const selectedTestResults = domainResults?.find(
      (result) => result.name === selectedTest
    );

    if (selectedTestResults) {
      const selectedModuleResults = selectedTestResults.contentanalysis.filter(
        (result) => result.sectiontype === selectedModule
      );

      if (selectedModuleResults) {
        let requiredData: BarChartData = {
          labels: [],
          datasets: [
            {
              label: "Scores in Percentage",
              data: [],
              backgroundColor: ["#2D728F", "#313715", "#E01A4F", "#2CDA9D", "#4A2545", "#EAC435", "#FF4A1C", "#803F7A"],
              borderRadius: 5,
              base: -5
            },
          ],
        };

        selectedModuleResults.forEach((result) => {
          let domainPercentage = parseFloat(
            (
              (result.correctquestionscount / result.totalcontentquestions) *
              100
            ).toFixed(1)
          );
          requiredData.labels.push(result.contenttype);
          requiredData.datasets[0].data.push(domainPercentage);
        });

        return requiredData;
      }
    }

    return {
      labels: [],
      datasets: [
        {
          label: "Scores in Percentage",
          data: [],
          backgroundColor: "#327AEC",
          borderRadius: 5,
        },
      ],
    };
  };

  useEffect(() => {
    if (domainResults) {
      setSelectedTest(domainResults[0]?.name);
      setSelectedModule("ReadingAndWriting");
    }
  }, [domainResults]);

  const graphData = generateGraphData();

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "#ffffff",
        rotation: -90,
        font: {
          size: 12,
          weight: "bold",
          family: "Gully",
        },
        formatter: (value, context) => {
            const label = context.chart.data.labels?.[context.dataIndex] as string;
            let maxLength = 15;
          
            if (value < 20) {
              return '';
            } else if (value >= 20 && value < 30) {
              maxLength = 4;
            } else if (value >= 30 && value < 50) {
              maxLength = 8;
            } else if (value >= 50 && value < 60) {
              maxLength = 14; 
            } else if (value >= 60 && value < 70) {
              maxLength = 18;
            } else if (value >= 80) {
              maxLength = label.length;
            }
          
            return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
          },
          
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "DOMAINS",
          color: "#000",
          font: {
            size: 12,
            weight: "bold",
            family: "Gully",
          },
        },
      },
      y: {
        min: -5,
        max: 100,
        beginAtZero: false,
        ticks: {
          color: "#000",
          stepSize: 20,
          callback: (value) => {
            return value === -5 ? "" : value;
          },
        },
        grid: {
          color: "#E5E7EB",
        },
        title: {
          display: true,
          text: "SCORES IN PERCENTAGE",
          color: "#000",
          font: {
            size: 14,
            weight: "bold",
            family: "Gully",
          },
        },
      },
    },
  };
  

  const handleTestSelect = (testName: string) => {
    setSelectedTest(testName);
    setTestDropdownOpen(false);
  };

  const handleModuleSelect = (moduleName: string) => {
    setSelectedModule(moduleName);
    setSectionDropdownOpen(false);
  };

  const handleSectionDropdown = () => {
    setSectionDropdownOpen(!sectionDropdownOpen);
    setTestDropdownOpen(false);
  };

  const handleTestDropdown = () => {
    setSectionDropdownOpen(false);
    setTestDropdownOpen(!testDropdownOpen);
  };

  return (
    <div
      className="w-full flex flex-col py-3 px-6 gap-3  rounded-xl"
      style={{ boxShadow: "0px 0px 10px 3px rgba(32, 32, 32, 0.10)" }}
    >
      <p className="text-center font-gully text-[21px] font-medium">
        Domain level performance
      </p>

      <div className="w-full flex justify-between items-center gap-6">
        <div className="font-gully text-xs font-semibold text-appPrimaryColor flex items-center relative">
          <p>Sections:</p>
          <button onClick={handleSectionDropdown} className="flex ml-1">
            {selectedModule === "Mathematics" ? "Math" : "R&W"}
            {sectionDropdownOpen ? (
              <IoIosArrowUp className="w-4 h-4 my-auto" />
            ) : (
              <IoIosArrowDown className="w-4 h-4 my-auto" />
            )}
          </button>

          {sectionDropdownOpen && (
            <div className="absolute top-6 left-0 bg-white rounded-xl shadow-lg w-48 max-h-52 overflow-y-auto border">
              <button
                className={`py-[18px] px-[14px] w-full text-base font-gully font-medium text-start hover:bg-appPrimaryColor hover:text-white text-gray-900 rounded-t-xl
                    ${
                      selectedModule === "ReadingAndWriting"
                        ? "bg-appPrimaryColor text-white"
                        : ""
                    }`}
                onClick={() => handleModuleSelect("ReadingAndWriting")}
              >
                Reading & Writing
              </button>
              <button
                className={`py-[18px] px-[14px] w-full text-base font-gully font-medium text-start hover:bg-appPrimaryColor hover:text-white text-gray-900 rounded-b-xl 
                    ${
                      selectedModule === "Mathematics"
                        ? "bg-appPrimaryColor text-white"
                        : ""
                    }`}
                onClick={() => handleModuleSelect("Mathematics")}
              >
                Math
              </button>
            </div>
          )}
        </div>

        <div className="font-gully text-xs font-semibold text-appPrimaryColor flex items-center relative">
          <p>Test:</p>
          <button onClick={handleTestDropdown} className="flex ml-1">
            {selectedTest}
            {testDropdownOpen ? (
              <IoIosArrowUp className="w-4 h-4 my-auto" />
            ) : (
              <IoIosArrowDown className="w-4 h-4 my-auto" />
            )}
          </button>

          {testDropdownOpen && (
            <div className="absolute top-6 right-0 bg-white rounded-xl shadow-lg w-28 max-h-64 overflow-y-auto scroll4 border">
              {domainResults?.length > 0 ? (
                domainResults.map((test, index) => {
                  const isSelected = test.name === selectedTest;
                  return (
                    <button
                      key={test.testtakendate}
                      className={`py-[18px] px-[14px] w-full text-base font-gully font-medium text-start  hover:bg-appPrimaryColor hover:text-white text-gray-900
                    ${index === 0 ? "rounded-t-xl" : ""}
                    ${index === domainResults.length - 1 ? "rounded-b-xl" : ""}
                    ${isSelected ? "bg-appPrimaryColor text-white" : ""}`}
                      onClick={() => handleTestSelect(test.name)}
                    >
                      {test.name.length > 10 ? `${test.name.substring(0, 10)}...` : test.name}
                    </button>
                  );
                })
              ) : (
                <div className="py-[18px] px-[14px] text-gray-500">
                  No students available
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="w-full min-h-[300px] max-h-[400px]">
        <Bar data={graphData} options={options} />
      </div>
    </div>
  );
};

export default DomainWidget;
