import React from "react";
import LaptopGirl from "../../assets/Images/SocialWoman.svg";
import { useNavigate } from "react-router-dom";
import AuthButton from "./LoginButton";
import { BiMailSend } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { appleClientId, appleUrl, googleUrl } from "../../apiConfig";
import AppleLogin from 'react-apple-login'
import { BsApple } from "react-icons/bs";

const SocialSignup = () => {
  const navigate = useNavigate();

  const handleGoogleSignUp = () => {
    window.open(googleUrl, "_self");
  };

  return (
    <div className="bg-white flex justify-center items-center h-screen w-full mx-auto font-gully">
      <div className="w-1/2 h-screen hidden lg:block">
        <img
          src={LaptopGirl}
          alt="Placeholder"
          className="object-cover w-full h-screen"
        />
      </div>

      <div className="w-1/2 flex flex-col items-center lg:p-[120px] 3xl:p-[250px]">
        <div className="w-full flex flex-col justify-center items-center gap-9">
          <div className="flex justify-center items-center">
            <p className="font-bold text-[#283044] text-center text-4xl font-pretendard cursor-pointer">
              FullMarks! <span className="text-appPrimaryColor">SATprep</span>
            </p>
          </div>

          <div className="gap-3 flex flex-col justify-center items-center">
            <AuthButton
              text="Continue with Email"
              icon={<BiMailSend />}
              iconPosition="left"
              onClick={() => navigate("/register")}
              className="text-xl w-80"
            />
            <AppleLogin  
              clientId={appleClientId} 
              scope={'name email'}
              redirectURI={appleUrl}   
              responseType={"code id_token"} 
              responseMode={"form_post"}  
              render={(renderProps) => (
                <button 
                  onClick={renderProps.onClick} 
                  className="text-xl font-bold rounded-xl bg-white text-appPrimaryColor border border-appPrimaryColor w-80 py-3 px-[34px] flex justify-center cursor-pointer"
                >
                  <BsApple className="text-black" size={24}  />
                  <p className="ml-2 pt-0.5">Sign up with Apple</p>
                </button>
              )}
              usePopup={false}
            />
            <AuthButton
              text="Continue with Google"
              icon={<FcGoogle size={24} className="mb-1" />}
              iconPosition="left"
              onClick={handleGoogleSignUp}
              className="text-xl bg-white !text-appPrimaryColor border border-appPrimaryColor w-80 !pb-3"
            />
          </div>

          <div className="flex justify-center items-center gap-1">
            <p className="text-[#202020]">Already have an account?</p>
            <button
              className="underline text-[#327AEC] flex justify-center text-base cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign in!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialSignup;
