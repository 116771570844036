import React, { ChangeEvent, useState } from "react";
import {
  useDiscardTestMutation,
  useGetDiscardInfoOptionsQuery,
  useLazyResumeTestQuery,
} from "../../redux/API/StartTest/startTestApi";
import {
  setStartedTestId,
  StartedTestId,
} from "../../redux/Slices/startFullTestSlice";
import Dropdown from "../../pages/LogInPage/Dropdown";
import { MdArrowBackIosNew } from "react-icons/md";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { RiDeleteBinLine } from "react-icons/ri";
import { RootState } from "../../redux/Store/store";

const DiscardInfo = () => {
  const dispatch = useDispatch();
  const [discardTest] = useDiscardTestMutation();
  const [resumeTest] = useLazyResumeTestQuery();
  const {data:optionData} = useGetDiscardInfoOptionsQuery({});
  const [selectedReason, setSelectedReason] = useState({
    reason: "",
    description: "",
    isTextArea: false
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { startedTestId } = useSelector(
    (state: RootState) => state.root.startFullTest
  );

  const handleDiscardTest = async () => {
    try {
      if (startedTestId.userTestId) {
        setSubmitLoader(true);
        let payload = {
          userTestId: startedTestId.userTestId,
          reason: selectedReason.reason,
          description: selectedReason.description,
        };
        const response = await discardTest(payload).unwrap();
        if (response.status === "success") {
          const resumeResponse = await resumeTest({});
          if (resumeResponse.isSuccess) {
            let payload = {
              testId: resumeResponse.data?.testId,
              testName: resumeResponse.data?.testname,
              createdDate: resumeResponse.data?.createdDate,
              userTestId : resumeResponse.data?.userTestId ?? '',
            };
            dispatch(setStartedTestId(payload));
          }
          setSubmitLoader(false);
          dispatch(closeDialog());
        }
      }
    } catch (e) {
      setSubmitLoader(false);
      console.error(e, "Discard Test");
    }
  };
  const handleDropdownChange = (val: any) => {
    if (!val.isTextArea) {
      setSelectedReason({ reason: val.reason, description: "", isTextArea: false });
    } else {
      setSelectedReason((prev) => ({ ...prev, reason: val.reason, isTextArea: val.isTextArea }));
    }
  };
  const handleDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedReason((prev) => ({ ...prev, description: e.target.value }));
  };
  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };
  const getDiscardDisabled = () => {
    if (selectedReason.reason === "") {
      return true;
    }

    if (
      selectedReason.reason === "Other reasons" &&
      selectedReason.description.trim() === ""
    ) {
      return true;
    }

    if (submitLoader) {
      return true;
    }
    return false;
  };
  
  return (
    <div className="flex flex-col py-2 px-10 gap-12 justify-between items-center rounded-xl">
      <h3 className="text-black text-center font gully text-[40px] font-bold leading-10">
        Tell is why you would like to discard the test
      </h3>
      <div className="flex flex-col items-start gap-6 w-full">
        <div className="flex flex-col justify-center items-start gap-2 w-full">
          <p className="text-[#283044] font-gully text-xs font-semibold uppercase">
            Select Reason
          </p>
          <div className="w-full -mt-6">
            <Dropdown
              getOption={(option: any) => `${option.reason}`}
              onChangeHandler={handleDropdownChange}
              setOpen={setDropdownOpen}
              open={dropdownOpen}
              selected={selectedReason.reason}
              options={optionData?.data || []}
              label="Select"
              name="Select"
            />
          </div>
        </div>

        {selectedReason.isTextArea && (
          <div className="flex flex-col justify-center items-start gap-2 w-full">
            <p className="text-[#283044] font-gully text-xs font-semibold uppercase">
              Specify Reason
            </p>
            <textarea
              className="flex w-full h-[208px] py-3 px-6 text-base font-gully font-medium  rounded-[12px] border border-[#646464] bg-[#F5F9FF] shadow-sm"
              placeholder="Type here..."
              value={selectedReason.description}
              onChange={handleDescription}
            />
          </div>
        )}
      </div>

      <div className="flex justify-between items-center self-stretch">
        <AuthButton
          text="Back"
          icon={<MdArrowBackIosNew />}
          iconPosition="left"
          onClick={handleCloseDialog}
          loader={false}
          disabled={false}
        />
        <AuthButton
          text="Discard"
          icon={<RiDeleteBinLine />}
          iconPosition="left"
          onClick={handleDiscardTest}
          loader={submitLoader}
          disabled={getDiscardDisabled()}
        />
      </div>
    </div>
  );
};

export default DiscardInfo;
