import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyLoginOauthUserQuery } from "../../redux/API/Login/loginApi";
import ErrorLogo from "../../assets/Images/PageNotFoundError.svg";
import { setItem } from "../../utils/token";
import { jwtDecode } from "jwt-decode";

const OAuthVerification = () => {
  const [searchParams] = useSearchParams();
  const [loginOauthUser, { isError }] = useLazyLoginOauthUserQuery();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    if (response.isFirstTimeLogin) {
      navigate("/profilesetup");
    }
    const { token, isLogin, refreshToken, route } = response;
    setItem("isLogin", isLogin);
    if (token) {
      setItem("token", token);
      setItem("refreshToken", refreshToken);
      const decoded: any = jwtDecode(token);
      setItem("userName", decoded?.firstName);
      setItem("userRole", decoded?.role ?? null);
      setItem("userEmail", decoded?.userEmail);
      const expiryTime = decoded.exp;
      if (decoded.role && !route) {
        decoded.role === "PARENT"
          ? navigate("/parentdashboard")
          : navigate("/dashboard");
      } else if (decoded.role === "PARENT" && route) {
        navigate(route);
      } else if (decoded.role === "STUDENT" && route) {
        navigate(route);
      }
      if (expiryTime) {
        setItem("expiryTime", expiryTime.toString());
      }
    }
  };

  useEffect(() => {
    const userLogin = async () => {
      const code = searchParams.get("code");
      if (code) {
        try {
          const response = await loginOauthUser(code).unwrap();
          if (response.isLogin) {
            handleSuccess(response);
          } else {
            setError(true);
          }
        } catch (e) {
          console.error(e);
          setError(true);
        }
      }
    };
    userLogin();
  }, [searchParams]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {isError || error ? (
        <div className="flex flex-col justify-center gap-9 self-stretch h-[80vh]">
          <div className="flex flex-col items-center justify-center gap-9">
            <img
              src={ErrorLogo}
              alt="error-logo"
              className="mx-auto w-[400px] h-[400px]"
            />

            <div className="font-gully text-[21px] font-medium text-center">
              <p>Please try logging in again.</p>
              <p>
                If the issue persists,Reach out to our support team at{" "}
                <a
                  href="mailto:support@fullmarks.io"
                  className="text-appPrimaryColor underline"
                >
                  {" "}
                  support@fullmarks.io
                </a>
                .{" "}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="font-gully text-[40px] font-bold text-appPrimaryColor">
          Redirecting you to SAT,Please wait...
        </h1>
      )}
    </div>
  );
};

export default OAuthVerification;
